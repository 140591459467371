import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

export const theme = responsiveFontSizes(createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            contrastText: '#fff',
            light: '#1ed760',
            main: '#1db954',
        },
        secondary: {
            main: '#fb1d97'
        },
    },
}));