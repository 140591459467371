import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

// import RecentTracks from '../components/RecentTracks';
import RecentTabs from '../components/RecentTabs'
import { Container, TextField } from '@material-ui/core';

const parseSpotifyTrackId = (input) => {
    try {
        const parsedUrl = new URL(input, 'https://open.spotify.com');
        const pathMatch = parsedUrl.pathname.match(/^\/track\/([a-zA-Z0-9]+)(?:\/.*)?$/);
        if (pathMatch && pathMatch[1]) {
            return pathMatch[1];
        }
    } catch (error) {

    }

    const uriMatch = input.match(/^spotify:track:([a-zA-Z0-9]+)(?:[^a-zA-Z0-9].*)?$/);
    return uriMatch && uriMatch[1];
};

const MainContainer = styled(Container)`
    ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
        margin-top: 60px;
    }
    `}
`;

export default function CreateCheck({ spotifyToken }) {
    const [inputValue, setInputValue] = useState('');
    const history = useHistory();

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };

    const handlePaste = (event) => {
        const parsed = parseSpotifyTrackId(event.clipboardData.getData('Text'));
        if (parsed) {
            history.push(`/create/${parsed}`);
        }
    };

    return (
        <>
            <MainContainer maxWidth='sm'>
                <TextField color='secondary' fullWidth label='Paste a Spotify URL here' onChange={handleChange} onPaste={handlePaste} value={inputValue} variant='outlined' />
                <RecentTabs />
            </MainContainer>
        </>
    );
}
