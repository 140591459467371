export const breakpoints = {
    container: '1092px',
    desktop: '992px',
    mobile: '240px',
    mobileLandscape: '480px',
    tablet: '768px',
};

export const colors = {
    grayDark: '#121212',
    grayLight: '#b3b3b3',
    grayMedium: '#282828',
    spotifyLightGreen: '#1ed760',
    spotifyGreen: '#1db954',
};

export const sizes = {
    container: 1092,
    headerHeight: 60,
};
