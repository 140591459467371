import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';

import Track from '../components/Track';
import ShareCheck from '../components/ShareCheck';
import {
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from '@material-ui/core';

const MainContainer = styled(Container)`
    ${({ theme }) => `
    align-items: center;    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: ${theme.spacing(6)}px;

    & > * {
        margin: ${theme.spacing(2, 0, 2)};
    }
    `}
`;
const ActionsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const GET_TRACK = gql`
    query GetTrack($spotifyTrackId: ID!) {
        spotifyTrack(spotifyTrackId: $spotifyTrackId) {
            albumName
            artistNames
            largestImageUrl
            name
        }
    }
`;

const CREATE_CHECK = gql`
    mutation CreateCheck($spotifyTrackId: String!) {
        createCheck(spotifyTrackId: $spotifyTrackId) {
            checkId
        }
    }
`;

export default function ConfirmCreateCheck({ spotifyToken }) {
    const history = useHistory();
    if (!spotifyToken) {
        history.push('/');
    }
    const { spotifyTrackId } = useParams();
    const [isDialogDismissed, setDialogDismissed] = useState(false);
    const { data: trackData, error: trackError, loading: trackLoading } = useQuery(GET_TRACK, {
        variables: { spotifyTrackId }
    });
    const [createCheck, { error: createError, loading: createLoading, data: createData }] = useMutation(CREATE_CHECK, {
        onError: () => {
            setDialogDismissed(false);
        }
    });

    const goBack = () => {
        history.push('/create');
    };

    const handleCreateButtonPress = () => {
        createCheck({ variables: {spotifyTrackId }});
    };

    const closeDialog = () => {
        setDialogDismissed(true);
    };

    if (trackError) {
        return (
            <Dialog open={true}>
                <DialogTitle>{'Error getting track:'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{trackError}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={goBack}>Choose another track</Button>
                </DialogActions>
            </Dialog>
        );
    }

    const renderCreateActions = () => (
        <>
            { createLoading ?
                <CircularProgress color='secondary' /> :
                <Button color='primary' disabled={!trackData} onClick={handleCreateButtonPress} size='large' variant='outlined'>Create Link</Button>
            }
            <Typography variant='body2'>or</Typography>
            <Button onClick={goBack} size='small'>Choose another song</Button>
        </>
    );

    const renderShareActions = () => {
        const { name, artistNames } = trackData.spotifyTrack;
        const { checkId } = createData.createCheck;
        return (
            <ShareCheck 
                text={`Have you saved ${name} by ${artistNames[0]}?`} 
                title={`Have you saved ${name} by ${artistNames[0]}`}
                url={`${process.env.REACT_APP_HOSTNAME}/checks/${checkId}`}
                viewPath={`/checks/${checkId}`}
            />
        );
    };

    return (
        <>
            <MainContainer>
                { trackLoading && <CircularProgress color='secondary' /> }
                { trackData && <Track track={trackData.spotifyTrack} /> }
                <ActionsContainer>
                    { (createData && !createError) ? renderShareActions() : renderCreateActions() }
                </ActionsContainer>
            </MainContainer>

            <Dialog open={!!createError && !isDialogDismissed}>
                <DialogTitle>{'Error creating link:'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{createError}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
