import React, { useState } from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { Button, IconButton, InputAdornment, Snackbar, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FileCopy as FileCopyIcon, OpenInNew as OpenIcon, Share as ShareIcon } from '@material-ui/icons';

const ShareContainer = styled.div`
    ${({ theme }) => `
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & > * {
        margin: ${theme.spacing(1, 0)};
    }
    `}
`;
const ButtonContainer = styled.div`
    ${({ theme }) => `
    & > * {
        margin: ${theme.spacing(0, 1)};
    }
    `}
`;

export default function ShareCheck({ text, title, url, viewPath }) {
    const [copyStatus, setCopyStatus] = useState(null);
    const [isSnackbarOpen, setSnackbarOpen] = useState(false);
    const [hideNativeShare, setHideNativeShare] = useState(false);
    const isNativeShareSupported = !!navigator.share;
    let textFieldRef = React.createRef();

    const handleNativeShareClick = () => {
        if (navigator.share) {
            navigator.share({
                text,
                title,
                url
            });
        } else {
            setHideNativeShare(true);
        }
    };

    const handleCopyButtonClick = () => {
        try {
            textFieldRef.current.select();
            document.execCommand('copy');
            setCopyStatus('success');
            setSnackbarOpen(true);
        } catch (error) {
            setCopyStatus('error');
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <ShareContainer>
            <Typography variant='body2'>Share the link to see if your friends have saved the song:</Typography>
            <TextField 
                color='secondary'
                fullWidth
                inputRef={textFieldRef}
                value={url}
                variant='outlined'
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton onClick={handleCopyButtonClick}>
                                <FileCopyIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <ButtonContainer>
                { (isNativeShareSupported && !hideNativeShare) &&
                    <Button endIcon={<ShareIcon />} onClick={handleNativeShareClick} variant='outlined'>Share</Button>
                }
                <Button component={Link} endIcon={<OpenIcon />} to={viewPath} variant='outlined'>View</Button>
            </ButtonContainer>
            <Snackbar autoHideDuration={3000} onClose={handleSnackbarClose} open={isSnackbarOpen}>
                <Alert onClose={handleSnackbarClose} severity={copyStatus} variant='filled'>
                    {copyStatus === 'success' ? 'Copied' : 'Failed to copy'}
                </Alert>
            </Snackbar>
        </ShareContainer>
    )
}