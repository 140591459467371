import { createGlobalStyle } from 'styled-components';
import { colors } from './theme';

const GlobalStyle = createGlobalStyle`
    body {
        background-color: ${colors.grayDark};
        color: white;
        font-family: 'Roboto', sans-serif;
        margin: 0;
        min-height: 100vh;
        padding: 0;
        position: relative;
    }

    ::selection {
        background: #fb1d97;
    }
`;

export default GlobalStyle;
