import React, { useEffect } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useLocation, useParams } from 'react-router-dom';

import SpotifyLoginButton from '../components/SpotifyLoginButton';
import Track from '../components/Track';
import SpotifyEmbed from '../components/SpotifyEmbed';
import { Avatar, CircularProgress, Container, Typography, Zoom } from '@material-ui/core';
import { Favorite as FavoriteIcon, NotInterested as NotInterestedIcon } from '@material-ui/icons';

const MainContainer = styled(Container)`
    align-items: center;
    display: flex;
    flex-direction: column;
`;
const RequesterContainer = styled.div`
    ${({ theme }) => `
    align-items: center;
    display: flex;
    justify-content: center;
    margin: ${theme.spacing(6, 0, 4)};
    max-width: 100%;

    ${theme.breakpoints.up('sm')} {
        margin: ${theme.spacing(2, 0)};
    }

    & > * {
        margin: ${theme.spacing(1)}px;
    }
    `}
`;
const RequesterAvatar = styled(Avatar)`
    ${({ theme }) => `
    height: ${theme.spacing(6)}px;
    width: ${theme.spacing(6)}px;

    ${theme.breakpoints.up('sm')} {
        height: ${theme.spacing(8)}px;
        width: ${theme.spacing(8)}px;
    }
    `}
`;
const AnswerContainer = styled.div`
    ${({ theme }) => `
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing(4, 0, 8)};
    `}
`;
const AnswerAvatar = styled(Avatar)`
    ${({ saved, theme }) => `
    background-color: ${saved === 'true' ? theme.palette.success.main : theme.palette.error.main};
    color: #fff;
    height: ${theme.spacing(6)}px;
    width: ${theme.spacing(6)}px;
    `}
`;

const GET_CHECK = gql`
    query GetCheck($checkId: ID!) {
        check(checkId: $checkId) {
            answers {
                isSaved
                spotifyUser {
                    largestImageUrl
                    name
                }
            }
            spotifyTrack {
                albumName
                artistNames
                largestImageUrl
                name
                spotifyTrackId
            }
            spotifyUser {
                largestImageUrl
                name
            }
        }
    }
`;

const ANSWER_CHECK = gql`
    mutation AnswerCheck($checkId: ID!) {
        answerCheck(checkId: $checkId) {
            isSaved
        }
    }
`;

export default function Check({ spotifyToken }) {
    const { checkId } = useParams();
    const { data: checkData, error: checkError, loading: checkLoading } = useQuery(GET_CHECK, {
        variables: { checkId }
    });
    const [answerCheck, { data: answerCheckData, error: answerCheckError, loading: answerCheckLoading }] = useMutation(ANSWER_CHECK);
    useEffect(() => {
        if (!spotifyToken ||
            checkLoading || checkError || !checkData || 
            answerCheckLoading || answerCheckError || answerCheckData) {
            return;
        }
        answerCheck({
            variables: { checkId }
        });
    });
    const { pathname } = useLocation();

    if (checkError) return `Error: ${checkError}`;
    if (checkLoading) return (
        <>
            <CircularProgress color='secondary' />
        </>
    );

    const renderAnswerCheck = () => {
        if (answerCheckError) return `Error checking: ${checkError}`;
        if (answerCheckLoading) return (
            <>
                <Typography variant='body2'>{'Checking whether you\'ve saved the track...'}</Typography>
                <CircularProgress color='secondary' />
            </>
        );
        if (!answerCheckData) return null;

        const { isSaved } = answerCheckData.answerCheck;
        const saved = isSaved ? 'true' : 'false';

        return (
            <>
                <Typography variant='body2'>You have { !isSaved && 'not' } saved this track</Typography>
                <Zoom in={true}>
                    <AnswerAvatar saved={saved}>
                        { isSaved ? <FavoriteIcon fontSize='large' /> : <NotInterestedIcon fontSize='large' /> }
                    </AnswerAvatar>
                </Zoom>
            </>
        );
    };

    const renderLoginCTA = () => (
        <>
            <Typography variant='body2'>Log in with Spotify to answer</Typography>
            <SpotifyLoginButton redirect={pathname}>Login with Spotify</SpotifyLoginButton>
        </>
    );

    const { check } = checkData;
    const { spotifyTrack, spotifyUser } = check;

    return (
        <MainContainer maxWidth='sm'>
            <RequesterContainer>
                <RequesterAvatar alt={spotifyUser.name} src={spotifyUser.largestImageUrl} />
                <Typography variant='body1'>{`${spotifyUser.name} wants to know if you've saved:`}</Typography>
            </RequesterContainer>
            <Track track={spotifyTrack} />
            <AnswerContainer>
                { spotifyToken ? renderAnswerCheck() : renderLoginCTA() }
            </AnswerContainer>
            { spotifyToken && <SpotifyEmbed spotifyTrackId={spotifyTrack.spotifyTrackId} /> }
        </MainContainer>
    )
}