import React, { useState } from 'react';
import styled from 'styled-components';

import { Tab, Tabs } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import RecentTracks from './RecentTracks';

const TabContentContainer = styled.div`
    ${({ theme }) => `
    margin: ${theme.spacing(2, 0, 0, 0)};
    `}
`;

export default function RecentTabs() {
    const [visibleTabIndex, setVisibleTabIndex] = useState(0);

    const handleTabChange = (event, newIndex) => {
        setVisibleTabIndex(newIndex);
    };

    const handleSwipe = (newIndex) => {
        setVisibleTabIndex(newIndex);
    };

    return (
        <>
            <Tabs onChange={handleTabChange} value={visibleTabIndex} variant='fullWidth'>
                <Tab label='Recently Played' />
                <Tab label='Recently Saved' />
            </Tabs>
            <TabContentContainer>
                <SwipeableViews axis='x' index={visibleTabIndex} onChangeIndex={handleSwipe}>
                    <RecentTracks type='plays' />
                    <RecentTracks type='saves' />
                </SwipeableViews>
            </TabContentContainer>
        </>
    );
}
