import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Typography, IconButton } from '@material-ui/core';
import { ArrowForward as ArrowIcon } from '@material-ui/icons';
import { join } from 'lodash';

const TrackRow = styled(Link)`
    ${({ theme }) => `
    align-items: center;
    color: white;
    display: flex;
    max-width: 100%;
    text-decoration: none;

    & > * {
        align-items: center;
        display: flex;
        
        justify-content: center;
        padding: ${theme.spacing(1)}px;
        position: relative;
    }
    `}
`;
const AlbumArtContainer = styled.div`
    width: 50px;
`;
const AlbumArt = styled.img`
    max-width: 100%;
`;
const InfoContainer = styled.div`
    ${({ theme }) => `
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 200px;

    & > * {
        max-width: 100%;
    }
    `}
`;
const ActionContainer = styled.div`
    width: 0px;
`;

export default function TrackInList({ track }) {
    const { albumName, artistNames, largestImageUrl, name, spotifyTrackId } = track;
    return (
        <TrackRow to={`/create/${spotifyTrackId}`}>
            <AlbumArtContainer>
                <AlbumArt src={largestImageUrl} />
            </AlbumArtContainer>
            <InfoContainer>
                <Typography align='left' noWrap={true} variant='body1'>{name}</Typography>
                <Typography align='left' noWrap={true} variant='body2'>{join(artistNames, ', ')}{' • '}{albumName}</Typography>
            </InfoContainer>
            <ActionContainer>
                <IconButton>
                    <ArrowIcon />
                </IconButton>
            </ActionContainer>
        </TrackRow>
    );
}