import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'query-string';

const LoadingMessage = styled.h1`
    font-size: 16px;
`;

export default function SpotifyCallback({ setSpotifyToken }) {
    const [error, setError] = useState(null);
    const history = useHistory();
    const { hash, search } = useLocation();

    useEffect(() => {
        const parsedSearch = search ? qs.parse(search) : {};
        const parsedHash = hash ? qs.parse(hash) : {};

        if (parsedSearch.error) {
            setError(parsedSearch.error);
        } else if (parsedHash.access_token) {
            console.log(parsedHash.access_token);
            setSpotifyToken(parsedHash.access_token);
            const state = parsedHash.state ? JSON.parse(parsedHash.state) : {};
            const redirect = state.redirect || '/';
            history.push(redirect);
        }
    }, [hash, history, search, setSpotifyToken]);

    if (error) {
        return (
            <LoadingMessage>Error: { error }</LoadingMessage>
        );
    }

    return (
        <LoadingMessage>Loading...</LoadingMessage>
    );
}