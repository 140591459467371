import React from 'react';
import { Route, Switch } from 'react-router-dom';

import RouteWithAppProps from './components/RouteWithAppProps';

import Home from './containers/Home';
import Check from './containers/Check';
import CreateCheck from './containers/CreateCheck';
import ConfirmCreateCheck from './containers/ConfirmCreateCheck';
import SpotifyCallback from './containers/SpotifyCallback';
import NotFound from './containers/NotFound';

export default function Routes({ appProps }) {
    return (
        <Switch>
            <RouteWithAppProps path='/' exact component={Home} appProps={appProps} />
            <RouteWithAppProps path='/checks/:checkId' exact component={Check} appProps={appProps} />
            <RouteWithAppProps path='/create' exact component={CreateCheck} appProps={appProps} />
            <RouteWithAppProps path='/create/:spotifyTrackId' exact component={ConfirmCreateCheck} appProps={appProps} />
            <RouteWithAppProps path='/callback' exact component={SpotifyCallback} appProps={appProps} />

            { /* Catch all route */ }
            <Route component={NotFound} />
        </Switch>
    );
}