import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { get, map } from 'lodash';

import TrackList from './common/TrackList';
import { CircularProgress } from '@material-ui/core';

const GET_RECENT_SAVES = gql`
    {
        recentSaves {
            items {
                track {
                    albumName
                    artistNames
                    largestImageUrl
                    name
                    spotifyTrackId
                }
            }
        }
    }
`;

const GET_RECENT_TRACKS = gql`
    {
        recentTracks {
            items {
                track {
                    albumName
                    artistNames
                    largestImageUrl
                    name
                    spotifyTrackId
                }
            }
        }
    }
`;

export default function RecentTracks({ type }) {
    const query = type === 'saves' ? GET_RECENT_SAVES : GET_RECENT_TRACKS;
    const { data, error, loading } = useQuery(query);

    if (error) return `Error getting recent tracks: ${error.message}`;
    if (loading) return <CircularProgress color='secondary' />;

    const resultKey = type === 'saves' ? 'recentSaves' : 'recentTracks';
    const tracks = get(data, `${resultKey}.items`);
    const formattedTracks = map(tracks, (track) => track.track);
    return (
        <TrackList tracks={formattedTracks} />
    );
}
