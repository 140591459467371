import React from 'react';
import styled from 'styled-components';
import { map, uniqBy } from 'lodash';

import TrackInList from './TrackInList';

const ListContainer = styled.div`
    max-width: 100%;
`;

export default function TrackList({ tracks }) {
    const uniqueTracks = uniqBy(tracks, 'spotifyTrackId');
    return (
        <ListContainer>
            {map(uniqueTracks, (track) => <TrackInList key={track.spotifyTrackId} track={track} />)}
        </ListContainer>
    );
}