import React from 'react';
import styled from 'styled-components';
import { join } from 'lodash';

import { Typography } from '@material-ui/core';

const TrackContainer = styled.div`
    ${({ theme }) => `
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    & > * {
        margin: ${theme.spacing(1, 0)};
    }
    `}
`;
const AlbumArt = styled.img`
    max-width: 100%;
    width: 200px;
`;

export default function Track({ track }) {
    const { albumName, artistNames, largestImageUrl, name } = track;
    return (
        <TrackContainer>
            <AlbumArt src={largestImageUrl} />
            <div>
                <Typography variant='h4'>{name}</Typography>
                <Typography variant='body2'>{`${join(artistNames, ', ')} • ${albumName}`}</Typography>
            </div>
        </TrackContainer>
    );
}