import React from 'react';
import { Button } from '@material-ui/core';

import { generateAuthUrl } from '../lib/spotify';

export default function SpotifyLoginButton({ children, redirect, scope }) {
    const spotifyAuthUrl = generateAuthUrl({ redirect, scope });
    return (
        <Button href={spotifyAuthUrl} color='primary' variant='outlined'>{children}</Button>
    );
}
