import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import { theme } from './styles/muiTheme';
import GlobalStyle from './styles/globalStyle';
import Routes from './Routes';
import Header from './components/Header';

let authToken;

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  request: (operation) => {
    if (authToken) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
    }
  }
});

function App() {
  const [spotifyToken, setSpotifyToken] = useState(null);
  authToken = spotifyToken;

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            <GlobalStyle />
            <Router>
              <Header isLoggedIn={!!spotifyToken} />
              <Routes appProps={{ spotifyToken, setSpotifyToken }} />
            </Router>
          </ApolloProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
