import React from 'react';
import styled from 'styled-components';

import { Container, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SpotifyLoginButton from '../components/SpotifyLoginButton';

import travMainImg from '../images/trav-1.png';

const TravImage = styled.img`
    ${({ theme }) => `
    max-width: 375px;    
    width: 100%;

    ${theme.breakpoints.up('md')} {
        margin: 0;
    }
    `}
`;
const MainContainer = styled(Container)`
    ${({ theme }) => `
    align-items: center;
    display: flex;
    flex-direction: column;

    ${theme.breakpoints.up('md')} {
        flex-direction: row;
        min-height: 100vh;
        justify-content: center;
        padding: 0;
    }
    `}
`;
const MainInnerContainer = styled.div`
    ${({ theme }) => `
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;

    ${theme.breakpoints.up('md')} {
        height: 100vh;
        max-height: 800px;
        max-width: 50%;
    }
    `}
`;
const MainLeftContainer = styled(MainInnerContainer)`
    ${({ theme }) => `
    align-items: center;
    justify-content: center;
    text-align: center;

    ${theme.breakpoints.up('md')} {
        align-items: flex-end;
        text-align: right;
    }

    & > * {
        margin: ${theme.spacing(1, 0)};
    }
    `}
`;
const MainRightContainer = styled(MainInnerContainer)`
    align-items: center;
    justify-content: flex-end;
`;
const Footer = styled.div`
    ${({ theme }) => `
    align-items: center;
    background-color: rgba(18, 18, 18, 0.8);
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    padding: ${theme.spacing(3, 0)};
    position: fixed;
    right: 0;

    ${theme.breakpoints.up('md')} {
        align-items: flex-end;
        bottom: auto;
        justify-content: flex-start;
        left: auto;
        padding: 0;
        position: relative;
        right: auto;
    }
    `}
`;

export default function Home({ spotifyToken }) {
    const message = spotifyToken ? 'Create a link to find out!' : 'Log in with Spotify to find out!';
    const button = spotifyToken ?
                   <Button color='primary' component={Link} to='/create' variant='outlined'>Create a Link</Button> :
                   <SpotifyLoginButton>Login with Spotify</SpotifyLoginButton>;

    return (
        <>
            <MainContainer disableGutters={true} maxWidth='lg'>
                <MainLeftContainer>
                    <Typography variant='h2'>{'Has Travis Saved This Song?'}</Typography>
                    <Typography variant='body1'>{message}</Typography>
                    {button}
                </MainLeftContainer>
                <MainRightContainer>
                    <TravImage src={travMainImg} />
                </MainRightContainer>
            </MainContainer>
            <Footer>
                <Typography variant='body1'>{message}</Typography>
                {button}
            </Footer>
        </>
    );
}
