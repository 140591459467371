import qs from 'query-string';

const SPOTIFY_AUTH_BASE_URL = 'https://accounts.spotify.com/authorize';
const DEFAULT_SCOPE = 'user-read-email user-library-read user-read-recently-played';

export function generateAuthUrl({ redirect, scope }) {
    const spotifyAuthOptions = {
        client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_SPOTIFY_REDIRECT_URL,
        response_type: 'token',
        scope: scope || DEFAULT_SCOPE,
        state: redirect && JSON.stringify({ redirect })
    };
    return `${SPOTIFY_AUTH_BASE_URL}?${qs.stringify(spotifyAuthOptions)}`;
};
