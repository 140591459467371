import React from 'react';

export default function SpotifyEmbed({ spotifyTrackId }) {
    const iframeUrl = `https://open.spotify.com/embed/track/${spotifyTrackId}`;

    return (
        <iframe
            allow='encrypted-media'
            allowtransparency='true'
            frameBorder='0'
            height='80'
            src={iframeUrl} 
            title='Spotify Embed'
            width='300'
        ></iframe>
    )
}