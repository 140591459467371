import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const NotFoundContainer = styled.div`

`;
const H1 = styled.h1`
    color: green;
`;

export default function NotFound() {
    return (
        <>
            <NotFoundContainer>
                <H1>Are you Travis's wallet in South Africa? Because we can't find you</H1>
                <Link to='/'>Return Home</Link>
            </NotFoundContainer>
        </>
    );
}
