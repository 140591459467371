import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import {
    AccountCircle as AccountCircleIcon,
    Add as AddIcon,
    Home as HomeIcon,
    Menu as MenuIcon,
} from '@material-ui/icons';

import { generateAuthUrl } from '../lib/spotify';

import travHeaderImg from '../images/trav-2.png';

const HeaderContainer = styled.div`
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: space-between;
    width: 100%;
`;
const HeaderItem = styled.div`
    ${({ theme }) => `
    align-items: center;
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
    margin: ${theme.spacing(2, 0)};
    min-width: 48px;
    `}
`;
const HeaderCenterItem = styled(HeaderItem)`
    flex: 1 0 auto;
`;
const HeaderLogo = styled.img`
    border-radius: 50%;
    height: 48px;
`;
const Menu = styled(List)`
    max-width: 90vw;
    min-width: 250px;
`;

export default function Header({ isLoggedIn }) {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    const authUrl = generateAuthUrl({
        redirect: location.pathname
    });

    const openMenu = () => {
        setMenuOpen(true);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <>
            <HeaderContainer>
                <HeaderItem>
                    <IconButton onClick={openMenu}>
                        <MenuIcon />
                    </IconButton>
                </HeaderItem>
                <HeaderCenterItem>
                    <Link to='/'>
                        <HeaderLogo src={travHeaderImg} />
                    </Link>
                </HeaderCenterItem>
                <HeaderItem>
                    {isLoggedIn &&
                        <IconButton component={Link} to='/create'>
                            <AddIcon />
                        </IconButton>
                    }
                </HeaderItem>
            </HeaderContainer>
            <Drawer onClose={closeMenu} open={isMenuOpen}>
                <Menu>
                    <ListItem button component={Link} onClick={closeMenu} to='/'>
                        <ListItemIcon><HomeIcon /></ListItemIcon>
                        <ListItemText primary='Home' />
                    </ListItem>
                    {isLoggedIn ?
                        (
                            <ListItem button component={Link} onClick={closeMenu} to='/create'>
                                <ListItemIcon><AddIcon /></ListItemIcon>
                                <ListItemText primary='Create a Link' />
                            </ListItem>
                        ) :
                        (
                            <ListItem button component='a' href={authUrl}>
                                <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                                <ListItemText primary='Log In' />
                            </ListItem>
                        )
                    }
                </Menu>
            </Drawer>
        </>
    );
}
